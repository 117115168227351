import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "creator-content-layout__cover__box" }
const _hoisted_2 = { class: "creator-content-layout__content" }
const _hoisted_3 = { class: "creator-content-layout__content__background" }
const _hoisted_4 = {
  id: "content",
  class: "creator-content-layout__content__wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['creator-content-layout', _ctx.isAuthenticated && 'auth'])
  }, [
    _createVNode(_Transition, { name: "slide-fade-full-top" }, {
      default: _withCtx(() => [
        (_ctx.showCover)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([
          'creator-content-layout__cover',
          _ctx.backgroundReversed && 'bgnd-rev',
          _ctx.fullCover && 'full-cover',
        ])
            }, [
              _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "coverContent")
              ])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "contentBackground")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 2))
}