import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "input-dropdown__option__with-sub-label"
}
const _hoisted_2 = { class: "input-dropdown__option__with-sub-label__icon-label-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "input-dropdown__icon"
}
const _hoisted_4 = { class: "input-dropdown__option__with-sub-label__label" }
const _hoisted_5 = { class: "input-dropdown__option__with-sub-label__sub-label" }
const _hoisted_6 = {
  key: 0,
  class: "flex justify-between w-full"
}
const _hoisted_7 = { class: "text-xs" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  class: "input-dropdown__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    label: _ctx.$attrs.label,
    name: _ctx.$attrs.name,
    value: _ctx.$attrs.value,
    rules: _ctx.$attrs.rules,
    extra: _ctx.$attrs.extra,
    help: _ctx.$attrs.help,
    class: "input-dropdown"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_select, _mergeProps(_ctx.$attrs, {
        "show-search": _ctx.search,
        filterOption: _ctx.filterOption,
        "on-focus": _ctx.focusHandler,
        "on-blur": _ctx.blurHandler,
        onChange: _ctx.changeHandler,
        disabled: _ctx.isDisabled || _ctx.disabled,
        mode: _ctx.mode
      }), _createSlots({
        default: _withCtx(() => [
          (_ctx.isOptionsObject)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  value: option.value,
                  key: option.value,
                  disabled: option.disabled,
                  class: "input-dropdown__option"
                }, {
                  default: _withCtx(() => [
                    (option.subLabel)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          _createElementVNode("div", _hoisted_2, [
                            (_ctx.showIcons)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                                  (option.appIconName)
                                    ? (_openBlock(), _createBlock(_component_AppIcon, {
                                        key: 0,
                                        name: option.appIconName
                                      }, null, 8, ["name"]))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", _hoisted_4, _toDisplayString(option.label), 1)
                          ]),
                          _createElementVNode("div", _hoisted_5, _toDisplayString(option.subLabel), 1)
                        ]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (option.info)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("span", null, _toDisplayString(option.label), 1),
                                _createElementVNode("span", _hoisted_7, _toDisplayString(option.info), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                (_ctx.showIcons)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                      (option.appIconName)
                                        ? (_openBlock(), _createBlock(_component_AppIcon, {
                                            key: 0,
                                            name: option.appIconName
                                          }, null, 8, ["name"]))
                                        : _createCommentVNode("", true)
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("span", null, _toDisplayString(option.label), 1)
                              ]))
                        ], 64))
                  ]),
                  _: 2
                }, 1032, ["value", "disabled"]))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  value: option,
                  key: option,
                  disabled: option.disabled
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(option), 1)
                  ]),
                  _: 2
                }, 1032, ["value", "disabled"]))
              }), 128))
        ]),
        _: 2
      }, [
        _renderList(_ctx.$slots, (_, slot) => {
          return {
            name: slot,
            fn: _withCtx((scope) => [
              _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope || {})))
            ])
          }
        })
      ]), 1040, ["show-search", "filterOption", "on-focus", "on-blur", "onChange", "disabled", "mode"])
    ]),
    _: 3
  }, 8, ["label", "name", "value", "rules", "extra", "help"]))
}