import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: _normalizeClass(["layout-icon", `layout-icon--${_ctx.name}`]),
    src: _ctx.getLayoutIcon(_ctx.name),
    alt: _ctx.name
  }, null, 10, _hoisted_1))
}