import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "creator-content-cover-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_title, {
      level: 1,
      class: _normalizeClass(['text-display-1', _ctx.maxTitle && 'max'])
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _: 3
    }, 8, ["class"]),
    _createVNode(_component_a_typography_paragraph, { class: "creator-content-cover-heading__message" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ]))
}