import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "creator-content-submit__cover-form"
}
const _hoisted_2 = {
  key: 0,
  class: "creator-content-submit__content__bgnd"
}
const _hoisted_3 = { class: "creator-content-submit__content__graphic" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "creator-content-submit__content__card",
  id: "creator-content-submit__content__card"
}
const _hoisted_6 = {
  key: 0,
  class: "creator-content-submit__content__screen"
}
const _hoisted_7 = {
  key: 1,
  class: "creator-content-submit__content__screen"
}
const _hoisted_8 = { class: "block mb-1" }
const _hoisted_9 = { class: "creator-content-submit__media-uploader__wrapper" }
const _hoisted_10 = { class: "creator-content-submit__media-uploader__message" }
const _hoisted_11 = { class: "block mb-2" }
const _hoisted_12 = { class: "flex gap-5" }
const _hoisted_13 = { class: "w-1/2 flex-auto" }
const _hoisted_14 = { class: "block mb-2" }
const _hoisted_15 = { class: "w-1/2 flex-auto" }
const _hoisted_16 = { class: "block mb-2" }
const _hoisted_17 = { class: "pt-9" }
const _hoisted_18 = {
  key: 2,
  class: "creator-content-submit__content__screen"
}
const _hoisted_19 = { class: "block mb-2" }
const _hoisted_20 = { class: "block mb-2" }
const _hoisted_21 = { class: "block mb-2" }
const _hoisted_22 = { class: "block mb-2" }
const _hoisted_23 = { class: "pt-7" }
const _hoisted_24 = { class: "pt-3" }
const _hoisted_25 = {
  key: 1,
  class: "text-center creator-content-submit__content__screen-4"
}
const _hoisted_26 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreatorContentCoverHeading = _resolveComponent("CreatorContentCoverHeading")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_ArrowRightOutlined = _resolveComponent("ArrowRightOutlined")!
  const _component_CreatorContentCoverForm = _resolveComponent("CreatorContentCoverForm")!
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_LeftOutlined = _resolveComponent("LeftOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_InputRadio = _resolveComponent("InputRadio")!
  const _component_InputUpload = _resolveComponent("InputUpload")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_InputPhoneNumber = _resolveComponent("InputPhoneNumber")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_CreatorContentLayout = _resolveComponent("CreatorContentLayout")!

  return (_openBlock(), _createBlock(_component_CreatorContentLayout, {
    "full-cover": "",
    showCover: _ctx.currentStep == 1,
    class: "creator-content-submit"
  }, {
    coverContent: _withCtx(() => [
      _createVNode(_component_CreatorContentCoverHeading, null, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Sell your content to brands.")), 1)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.t(
            "XDeal should be a thing of the past. You can and should now monetize from the content you created for brands. Kamilas4am can handle the selling and licensing of your contents."
          )), 1)
        ]),
        _: 1
      }),
      (_ctx.currentStep == 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_a_typography_title, {
              level: 3,
              class: "creator-content-submit__cover-form__title"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Get started by entering the URL of your content.")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_CreatorContentCoverForm, {
              model: _ctx.step1FormState,
              onFinish: _ctx.step1SubmitHandler
            }, {
              buttonText: _withCtx(() => [
                _createVNode(_component_ArrowRightOutlined)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  value: _ctx.step1FormState.contentUrl,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.step1FormState.contentUrl) = $event)),
                  name: "contentUrl",
                  placeholder: _ctx.t('Enter a URL (can be Tiktok, IG, FB, Gdrive etc)'),
                  rules: [
              _ctx.makeRequiredRule(_ctx.t('Please enter the URL of your content.')),
              _ctx.makeValidUrlRule(),
            ]
                }, null, 8, ["value", "placeholder", "rules"])
              ]),
              _: 1
            }, 8, ["model", "onFinish"])
          ]))
        : _createCommentVNode("", true)
    ]),
    contentBackground: _withCtx(() => [
      (_ctx.currentStep != 4)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_LayoutIcon, {
              name: "brushBlue4",
              id: "brushBlue4",
              class: _normalizeClass(_ctx.currentStep != 1 && 'reversed')
            }, null, 8, ["class"])
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.currentStep != 4)
        ? (_openBlock(), _createBlock(_component_a_row, {
            key: 0,
            class: _normalizeClass([
        'creator-content-submit__content__grid',
        _ctx.currentStep != 1 && 'reversed',
      ]),
            gutter: [40, 40]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                span: 0,
                lg: 12
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("img", {
                      src: _ctx.graphicSrc1,
                      alt: "Graphic"
                    }, null, 8, _hoisted_4)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, {
                span: 24,
                lg: 12
              }, {
                default: _withCtx(() => [
                  (_ctx.currentStep == 3)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 0,
                        class: "block md:inline w-36 h-9 mb-8 py-1 px-1 text-sm",
                        type: "secondary",
                        size: "small",
                        onClick: _ctx.step3BackHandler
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_LeftOutlined),
                          _createTextVNode(_toDisplayString(_ctx.t("Go back")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.currentStep == 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_a_typography_title, {
                            level: 2,
                            class: "text-3xl md:text-4xl mb-16"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("How ‘BAKA NAMAN’ Works")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_typography_title, {
                            level: 3,
                            class: "text-2xl md:text-3xl mt-8 md:mt-12 mb-2"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("1. 📸 Submit")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_typography_paragraph, { class: "text-base md:text-xl" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("Submit the content you want to sell to brands.")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_typography_title, {
                            level: 3,
                            class: "text-2xl md:text-3xl mt-8 md:mt-12 mb-2"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("2. 👍 Wait and Share")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_typography_paragraph, { class: "text-base md:text-xl" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t(
                  "Share to the brands that your content is available for sale. You can also wait as we approach the brand for you."
                )), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_typography_title, {
                            level: 3,
                            class: "text-2xl md:text-3xl mt-8 md:mt-12 mb-2"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("3. 🤑 Get Paid")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_typography_paragraph, { class: "text-base md:text-xl" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t(
                  "If and when the brand buys your content, Kamila will process your payment to your preferred bank."
                )), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : (_ctx.currentStep == 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_a_typography_title, {
                              level: 3,
                              class: "text-2xl md:text-3xl mb-4"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("Tell us more about the brand")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_FormGroup, {
                              model: _ctx.step2FormState,
                              onFinish: _ctx.step2SubmitHandler
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.t("What are you uploading?")), 1),
                                  _createVNode(_component_InputRadio, {
                                    name: "contentType",
                                    value: _ctx.step2FormState.contentType,
                                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.step2FormState.contentType) = $event)),
                                    onChange: _ctx.contentTypeChangeHandler
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentTypeOptions, (option) => {
                                        return (_openBlock(), _createBlock(_component_a_radio, {
                                          key: option.value,
                                          value: option.value
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(option.text), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value", "onChange"])
                                ]),
                                _createVNode(_component_InputUpload, {
                                  name: "mediaFile",
                                  value: _ctx.step2FormState.mediaFile,
                                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.step2FormState.mediaFile) = $event)),
                                  "file-list-label": _ctx.mediaFileTypeValidations.fileListLabel,
                                  "max-count": 1,
                                  "max-file-size-in-mb": _ctx.mediaFileTypeValidations.maxFileSizeInMb,
                                  accept: null,
                                  capture: null,
                                  "validate-types": _ctx.mediaFileTypeValidations.validateTypes,
                                  draggable: "",
                                  rules: [_ctx.customMediaRule],
                                  class: "creator-content-submit__media-uploader"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_9, [
                                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t("Upload the high-res file of this content.")), 1),
                                      _createVNode(_component_a_button, {
                                        class: "creator-content-submit__media-uploader__button",
                                        type: "primary"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t("Upload now")), 1)
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["value", "file-list-label", "max-file-size-in-mb", "validate-types", "rules"]),
                                _createElementVNode("div", null, [
                                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.t("What’s the name of the brand you created for? *")), 1),
                                  _createVNode(_component_InputText, {
                                    value: _ctx.step2FormState.brand,
                                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.step2FormState.brand) = $event)),
                                    name: "brand",
                                    placeholder: _ctx.t('E.g. L’oreal, Apple, Samsung, Shake Shack'),
                                    rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                                  }, null, 8, ["value", "placeholder", "rules"])
                                ]),
                                _createElementVNode("div", _hoisted_12, [
                                  _createElementVNode("div", _hoisted_13, [
                                    _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.t("IG account of the brand")), 1),
                                    _createVNode(_component_InputText, {
                                      value: _ctx.step2FormState.brandInstagramUrl,
                                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.step2FormState.brandInstagramUrl) = $event)),
                                      name: "brandInstagramUrl",
                                      placeholder: _ctx.t('E.g. @phloreal, @apple.ph, etc')
                                    }, null, 8, ["value", "placeholder"])
                                  ]),
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.t("Price of your content")), 1),
                                    _createVNode(_component_InputDropdown, {
                                      value: _ctx.step2FormState.price,
                                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.step2FormState.price) = $event)),
                                      name: "price",
                                      placeholder: _ctx.t('Enter amount here'),
                                      options: _ctx.priceOptions
                                    }, null, 8, ["value", "placeholder", "options"])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_17, [
                                  _createVNode(_component_a_button, {
                                    class: "block md:inline w-full md:w-64 md:h-12 px-10",
                                    type: "primary",
                                    "html-type": "submit"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t("Next")), 1)
                                    ]),
                                    _: 1
                                  })
                                ])
                              ]),
                              _: 1
                            }, 8, ["model", "onFinish"])
                          ]))
                        : (_ctx.currentStep == 3)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                              _createVNode(_component_a_typography_title, {
                                level: 3,
                                class: "text-2xl md:text-3xl mb-4"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Tell us more about you")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_FormGroup, {
                                model: _ctx.step3FormState,
                                onFinish: _ctx.submitHandler
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_row, { gutter: 20 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_col, {
                                        span: 24,
                                        sm: 12
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.t("What’s your name? *")), 1),
                                          _createVNode(_component_InputText, {
                                            value: _ctx.step3FormState.creatorName,
                                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.step3FormState.creatorName) = $event)),
                                            name: "creatorName",
                                            rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                                          }, null, 8, ["value", "rules"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_col, {
                                        span: 24,
                                        sm: 12
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.t("Email Address *")), 1),
                                          _createVNode(_component_InputText, {
                                            value: _ctx.step3FormState.email,
                                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.step3FormState.email) = $event)),
                                            name: "email",
                                            rules: [
                      _ctx.makeRequiredRule(_ctx.t('This field is required.')),
                      _ctx.makeEmailRule(_ctx.t('Please enter a valid email address.')),
                    ],
                                            placeholder: _ctx.t('username@domain.com')
                                          }, null, 8, ["value", "rules", "placeholder"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_col, {
                                        span: 24,
                                        sm: 12
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.t("Phone Number")), 1),
                                          _createVNode(_component_InputPhoneNumber, {
                                            value: _ctx.step3FormState.phoneNumber,
                                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.step3FormState.phoneNumber) = $event)),
                                            name: "phoneNumber"
                                          }, null, 8, ["value"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_col, {
                                        span: 24,
                                        sm: 12
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.t("Your IG handle")), 1),
                                          _createVNode(_component_InputText, {
                                            value: _ctx.step3FormState.creatorInstagramUrl,
                                            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.step3FormState.creatorInstagramUrl) = $event)),
                                            name: "creatorInstagramUrl",
                                            placeholder: _ctx.t('@')
                                          }, null, 8, ["value", "placeholder"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createElementVNode("div", _hoisted_23, [
                                    _createVNode(_component_InputCheckbox, {
                                      value: _ctx.step3FormState.certify,
                                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.step3FormState.certify) = $event)),
                                      name: "certify",
                                      multiple: "",
                                      options: [
                    {
                      value: true,
                      label: _ctx.t(
                        'By submitting this form, I certify that I am the owner of the content.'
                      ),
                    },
                  ],
                                      rules: [
                    _ctx.makeRequiredRule(
                      _ctx.t(
                        'You need to certify that you are indeed the owner of the content.'
                      ),
                      'array'
                    ),
                  ]
                                    }, null, 8, ["value", "options", "rules"])
                                  ]),
                                  _createElementVNode("div", _hoisted_24, [
                                    _createVNode(_component_a_button, {
                                      class: "block md:inline w-full md:w-64 md:h-12 px-10",
                                      type: "primary",
                                      "html-type": "submit",
                                      loading: _ctx.loading,
                                      disabled: _ctx.loading
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t("Submit")), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["loading", "disabled"])
                                  ])
                                ]),
                                _: 1
                              }, 8, ["model", "onFinish"])
                            ]))
                          : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_25, [
            _createElementVNode("img", {
              src: _ctx.graphicSrc2,
              alt: "Graphic",
              class: "text-center creator-content-submit__content__screen-4__graphic"
            }, null, 8, _hoisted_26),
            _createVNode(_component_a_typography_title, {
              level: 2,
              class: "creator-content-submit__content__screen-4__title"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Thank you for submitting your content!")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_typography_paragraph, { class: "creator-content-submit__content__screen-4__message-1" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t(
            "Got another brilliant idea? Don't hesitate to submit again! We welcome your continuous creativity and look forward to seeing more from you."
          )), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", null, [
              _createVNode(_component_a_button, {
                class: "block md:inline w-full md:w-80 md:h-12 px-10",
                type: "link",
                onClick: _ctx.step4UploadAgainHandler
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Upload again")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createVNode(_component_a_typography_paragraph, { class: "creator-content-submit__content__screen-4__message-2" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t(
            "If a brand agrees to buy your content, we will reach out via email and ask for your bank details!"
          )), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", null, [
              _createVNode(_component_a_button, {
                class: "block md:inline w-full md:w-96 md:h-12 px-10",
                type: "primary",
                onClick: _ctx.step4ViewSubmissionsHandler
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("View your submissions")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["showCover"]))
}