
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    backgroundReversed: Boolean,
    fullCover: Boolean,
    showCover: Boolean,
  },
  setup() {
    const store = useStore();
    const isAuthenticated = store.state.auth.isAuthenticated;
    return {
      isAuthenticated,
    };
  },
});
