import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    label: _ctx.$attrs.label,
    name: _ctx.$attrs.name,
    rules: _ctx.$attrs.rules,
    class: "input-radio"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_radio_group, _mergeProps({
        value: _ctx.innerValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event))
      }, _ctx.$attrs, {
        options: _ctx.$attrs.options
      }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 16, ["value", "options"])
    ]),
    _: 3
  }, 8, ["label", "name", "rules"]))
}