import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    label: _ctx.$attrs.label,
    name: _ctx.$attrs.name,
    rules: _ctx.$attrs.rules,
    class: _normalizeClass(["input-checkbox", [_ctx.vertical && 'input-checkbox--vertical']])
  }, {
    default: _withCtx(() => [
      (_ctx.multiple)
        ? (_openBlock(), _createBlock(_component_a_checkbox_group, _mergeProps({
            key: 0,
            value: _ctx.checkboxValue,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkboxValue) = $event))
          }, _ctx.$attrs, {
            options: _ctx.$attrs.options
          }), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 16, ["value", "options"]))
        : (_openBlock(), _createBlock(_component_a_checkbox, _mergeProps({
            key: 1,
            checked: _ctx.checkboxValue,
            "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkboxValue) = $event))
          }, _ctx.$attrs), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.checkLabel), 1)
            ]),
            _: 1
          }, 16, ["checked"]))
    ]),
    _: 3
  }, 8, ["label", "name", "rules", "class"]))
}