
import { defineComponent } from "vue";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import { useVModel } from "vue-composable";

export default defineComponent({
  props: {
    value: String,
  },
  setup(props) {
    const value = useVModel(props, "value");

    return { innerValue: value };
  },
  components: {
    FormItem,
  },
});
