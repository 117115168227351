
import { defineComponent } from "vue";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import { useVModel } from "vue-composable";

export default defineComponent({
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    checkLabel: {
      type: String,
    },
    value: {
      type: [Boolean, Array],
    },
    vertical: Boolean,
  },
  setup(props) {
    const checkboxValue = useVModel(props, "value");

    return { checkboxValue };
  },
  emits: ["update:value"],
  components: {
    FormItem,
  },
});
