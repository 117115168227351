import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "creator-content-cover-form__field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createBlock(_component_FormGroup, _mergeProps({ class: "creator-content-cover-form" }, _ctx.$attrs), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createVNode(_component_a_button, {
        type: "primary",
        class: "creator-content-cover-form__submit",
        "html-type": "submit"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "buttonText", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.t("Submit")), 1)
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 16))
}