
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    FormGroup,
  },
});
