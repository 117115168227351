
import { ContentType } from "@/../__generated__/globalTypes";
import graphicSrc1 from "@/assets/graphics/creator-content-submit-graphic-1.png";
import graphicSrc2 from "@/assets/graphics/creator-content-submit-graphic-2.png";
import CreatorContentCoverForm from "@/shared/components/CreatorContent/CreatorContentCoverForm.vue";
import CreatorContentCoverHeading from "@/shared/components/CreatorContent/CreatorContentCoverHeading.vue";
import CreatorContentLayout from "@/shared/components/CreatorContent/CreatorContentLayout.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputCheckbox from "@/shared/components/Forms/InputCheckbox.vue";
import InputDropdown from "@/shared/components/Forms/InputDropdown.vue";
import InputPhoneNumber from "@/shared/components/Forms/InputPhoneNumber.vue";
import InputRadio from "@/shared/components/Forms/InputRadio.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import InputUpload from "@/shared/components/Forms/InputUpload.vue";
import LayoutIcon from "@/shared/components/Icons/LayoutIcon.vue";
import { useSubmitCreatorContent } from "@/shared/composables/useCreatorContent";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { scrollToTop } from "@/shared/utils/browser";
import {
  makeEmailRule,
  makeRequiredRule,
  makeValidUrlRule,
} from "@/shared/utils/validators/commonValidators";
import { ArrowRightOutlined, LeftOutlined } from "@ant-design/icons-vue";
import type { UploadFile } from "ant-design-vue";
import { computed, defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { redirectToCreatorContentSearch } = useRedirectHelper();

    const currentStep = ref(1);
    const step1FormState = reactive({
      contentUrl: "",
    });
    const step2FormState = reactive({
      mediaFile: [] as UploadFile[],
      brand: "",
      brandInstagramUrl: "",
      contentType: ContentType.VIDEO,
      price: 200,
    });
    const step3FormState = reactive({
      creatorName: "",
      email: "",
      phoneNumber: "",
      creatorInstagramUrl: "",
      certify: [],
    });
    const contentTypeHelper = computed(() => ({
      contentType: step2FormState.contentType,
      isPhoto: step2FormState.contentType === ContentType.PHOTO,
      isVideo: step2FormState.contentType === ContentType.VIDEO,
    }));
    const dynamicFormState = computed(() => {
      const { isPhoto, isVideo } = contentTypeHelper.value;
      return {
        photoPrice: isPhoto ? step2FormState.price : undefined,
        videoPrice: isVideo ? step2FormState.price : undefined,
        photo: isPhoto ? step2FormState.mediaFile : undefined,
        video: isVideo ? step2FormState.mediaFile : undefined,
      };
    });
    const step1SubmitHandler = () => {
      currentStep.value = 2;
      setTimeout(() => {
        scrollToTop();
      }, 50);
    };
    const step2SubmitHandler = () => {
      currentStep.value = 3;
      scrollToTop();
    };
    const step3BackHandler = () => {
      currentStep.value = 2;
      scrollToTop();
    };
    const {
      submitCreatorContentRequest,
      submitCreatorContentLoading: loading,
    } = useSubmitCreatorContent();
    const submitHandler = async () => {
      const response = await submitCreatorContentRequest(
        {
          link: step1FormState.contentUrl,

          brand: step2FormState.brand,
          brandInstagramUrl: step2FormState.brandInstagramUrl,
          contentType: step2FormState.contentType,
          photoPrice: dynamicFormState.value.photoPrice,
          videoPrice: dynamicFormState.value.videoPrice,

          creatorName: step3FormState.creatorName,
          email: step3FormState.email,
          phoneNumber: step3FormState.phoneNumber,
          creatorInstagramUrl: step3FormState.creatorInstagramUrl,
        },
        {
          photo: dynamicFormState.value.photo?.at(0)?.originFileObj,
          video: dynamicFormState.value.video?.at(0)?.originFileObj,
        }
      );

      if (response?.id) {
        currentStep.value = 4;
        /** No need to display any message as Screen 4
         * is already the success message. No need also to
         * display error message as any network/request
         * error will be display as message popup */
      }
    };
    const step4UploadAgainHandler = () => {
      step2FormState.mediaFile = [];
      step2FormState.contentType = ContentType.VIDEO;
      step2FormState.price = 200;
      step3FormState.certify = [];
      currentStep.value = 2;
    };
    const step4ViewSubmissionsHandler = () => {
      redirectToCreatorContentSearch({ creator: step3FormState.email });
    };
    const contentTypeChangeHandler = () => {
      step2FormState.mediaFile = [];
      step2FormState.price = 200;
    };
    const mediaFileTypeValidations = computed(() => {
      /** We only need to check if it is photo,
       * since the value can only be either PHOTO or VIDEO */
      const { isPhoto } = contentTypeHelper.value;
      return {
        fileListLabel: isPhoto ? t("Image File") : t("Video File"),
        validateTypes: isPhoto
          ? ["image/jpeg", "image/png"]
          : ["video/quicktime", "video/mp4"],
        maxFileSizeInMb: isPhoto ? 10 : 1000,
      };
    });
    const contentTypeOptions = [
      { text: t("Video"), value: ContentType.VIDEO },
      { text: t("Photo"), value: ContentType.PHOTO },
    ];
    const priceOptions = computed(() => {
      const isVerified = false;
      const info = t("Only for Verified Creators *");
      const { isPhoto } = contentTypeHelper.value;
      if (isPhoto) {
        return [
          { label: "200", value: 200 },
          { label: "400", value: 400 },
          {
            label: "600",
            value: 600,
            disabled: !isVerified,
            info,
          },
          {
            label: "800",
            value: 800,
            disabled: !isVerified,
            info,
          },
          {
            label: "1000",
            value: 1000,
            disabled: !isVerified,
            info,
          },
        ];
      }
      return [
        { label: "200", value: 200 },
        { label: "400", value: 400 },
        { label: "600", value: 600 },
        {
          label: "800",
          value: 800,
          disabled: !isVerified,
          info,
        },
        {
          label: "1000",
          value: 1000,
          disabled: !isVerified,
          info,
        },
        {
          label: "1500",
          value: 1500,
          disabled: !isVerified,
          info,
        },
      ];
    });
    const validateMedia = async (__, _: UploadFile[]) => {
      if (!step2FormState.mediaFile.length) {
        return Promise.reject(t("Please upload at least 1 media file."));
      }

      return Promise.resolve();
    };

    /** Custom rule validation for with and without sound fields */
    const customMediaRule = {
      validator: validateMedia,
      required: true,
      trigger: "change",
    };
    return {
      t,
      currentStep,
      step1FormState,
      step3FormState,
      step2FormState,
      graphicSrc1,
      graphicSrc2,
      mediaFileTypeValidations,
      contentTypeOptions,
      priceOptions,
      loading,
      step1SubmitHandler,
      step2SubmitHandler,
      step3BackHandler,
      submitHandler,
      step4UploadAgainHandler,
      step4ViewSubmissionsHandler,
      contentTypeChangeHandler,
      makeRequiredRule,
      makeEmailRule,
      makeValidUrlRule,
      customMediaRule,
    };
  },
  components: {
    CreatorContentLayout,
    CreatorContentCoverHeading,
    CreatorContentCoverForm,
    FormGroup,
    InputText,
    InputRadio,
    InputDropdown,
    InputPhoneNumber,
    InputCheckbox,
    InputUpload,
    LayoutIcon,
    ArrowRightOutlined,
    LeftOutlined,
  },
});
