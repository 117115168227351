
import { defineComponent, PropType } from "vue";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import LayoutIcon from "@/shared/components/Icons/LayoutIcon.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import { useVModel } from "vue-composable";
import { useI18n } from "vue-i18n";
import { UploadOutlined } from "@ant-design/icons-vue";
import type { UploadFile, UploadProps } from "ant-design-vue";
import { makeToast } from "@/shared/utils/toast";

export default defineComponent({
  props: {
    value: {
      type: Array,
      required: true,
    },
    /**
     * Use this custom hook instead when using `beforeUpload`.
     * This is to ensure that this always returns false (prevents default upload action)
     */
    onBeforeUpload: {
      type: Function as PropType<UploadProps["beforeUpload"]>,
    },
    triggerLabel: String,
    fileListLabel: String,
    /** Controls whether to use <a-upload> or <a-upload-dragger> component */
    draggable: Boolean,
    validateTypes: Array as PropType<string[]>,
    /** Only supply when we want to show file extensions accepted in label */
    extensionsForLabel: Array as PropType<string[]>,
    maxFileSizeInMb: Number,
    hideFileListPreview: Boolean,
    hideDeleteButton: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const fileListValue = useVModel(props, "value");

    const checkFileType = (file: UploadFile) => {
      if (!props.validateTypes || !file.type) return true;

      return props.validateTypes.includes(file.type);
    };

    const checkFileSize = (file: UploadFile) => {
      if (!props.maxFileSizeInMb || !file.size) return true;

      const fileSizeInMb = file.size / 1024 / 1024; // convert bytes to MB
      return fileSizeInMb < props.maxFileSizeInMb;
    };

    // Handlers
    const handleBeforeUpload: UploadProps["beforeUpload"] = (
      file,
      fileList
    ) => {
      // Call additional external hooks
      if (props.onBeforeUpload) props.onBeforeUpload(file, fileList);

      const isValidType = checkFileType(file);
      if (!isValidType) {
        makeToast(
          "error",
          "Error",
          t("You can only upload files of type {types}.", {
            types:
              props.extensionsForLabel?.join("/") ||
              props.validateTypes!.join(", "),
          })
        );
      }

      // Validate file size
      const isWithinFileSize = checkFileSize(file);
      if (!isWithinFileSize) {
        makeToast(
          "error",
          "Error",
          t("File must smaller than {n} MB.", props.maxFileSizeInMb!)
        );
      }

      return isValidType && isWithinFileSize;
    };

    const handleChange: UploadProps["onChange"] = (fileInfo) => {
      // Filter out files
      fileListValue.value = [
        ...fileInfo.fileList.filter(
          (file) =>
            checkFileSize(file as UploadFile) &&
            checkFileType(file as UploadFile)
        ),
      ];
    };

    /** Utility function to get the index of value */
    const getFileIndex = (file: any) => {
      return fileListValue.value.findIndex(
        (_file: any) => _file.uid === file.uid
      );
    };

    return { t, fileListValue, handleBeforeUpload, getFileIndex, handleChange };
  },
  emits: ["update:value"],
  components: {
    FormItem,
    UploadOutlined,
    LayoutIcon,
    AppIcon,
  },
});
