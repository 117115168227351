
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import checkmark from "@/assets/layout/checkmark.svg";
import userModal from "@/assets/layout/user-modal.svg";
import infoModal from "@/assets/layout/info-modal.svg";
import upload from "@/assets/layout/upload.svg";
import uploadFile from "@/assets/layout/upload-file.svg";
import tickets from "@/assets/layout/tickets.svg";
// For fancy backgrounds
import dotsHalfHeightYellow from "@/assets/layout/dots-half-height-yellow.svg";
import dotsHalfHeightWhite from "@/assets/layout/dots-half-height-white.svg";
import dotsYellow from "@/assets/layout/dots-yellow.svg";
import dotsWhite from "@/assets/layout/dots-white.svg";
import lines from "@/assets/layout/lines.svg";
import brushBlue from "@/assets/layout/brush-blue.svg";
import brushPink from "@/assets/layout/brush-pink.svg";
import brushYellow from "@/assets/layout/brush-yellow.svg";
import tiktok from "@/assets/layout/tiktok.svg";
import legal from "@/assets/layout/legal.svg";
import followers from "@/assets/layout/followers.svg";
import twinkle2 from "@/assets/layout/twinkle-2.svg";
import twinkle3 from "@/assets/layout/twinkle-3.svg";
import twinkle3_1 from "@/assets/layout/twinkle-3-v1.svg";
import stars5Yellow from "@/assets/layout/stars-5-yellow.svg";
import ideas from "@/assets/layout/ideas.svg";
import ideas2 from "@/assets/layout/ideas2.svg";
import subscribe from "@/assets/layout/subscribe.svg";
import playButton from "@/assets/layout/play-button.svg";
import heart from "@/assets/layout/heart.svg";
import userBadge from "@/assets/layout/user-badge.svg";
import tiktokBadge from "@/assets/layout/tiktok-badge.svg";
import squiggle from "@/assets/layout/squiggle.svg";
import briefcase from "@/assets/layout/kyc/briefcase.svg";
import cloche from "@/assets/layout/kyc/cloche.svg";
import ellipse from "@/assets/layout/kyc/ellipse.svg";
import handshake from "@/assets/layout/kyc/handshake.svg";
import hanger from "@/assets/layout/kyc/hanger.svg";
import horizontalLines from "@/assets/layout/kyc/horizontal-lines.svg";
import laptop from "@/assets/layout/kyc/laptop.svg";
import lips from "@/assets/layout/kyc/lips.svg";
import megaphone from "@/assets/layout/kyc/megaphone.svg";
import plane from "@/assets/layout/kyc/plane.svg";
import roundedRectangle from "@/assets/layout/kyc/rounded-rectangle.svg";
import target from "@/assets/layout/kyc/target.svg";
import web from "@/assets/layout/kyc/web.svg";
// For PlanCard component
import planBgPink from "@/assets/layout/plans/pink.svg";
import planBgBlue from "@/assets/layout/plans/blue.svg";
import planBgYellow from "@/assets/layout/plans/yellow.svg";
// For ProfileCard component
import personWhite from "@/assets/layout/profile/person-white.svg";
import userStep from "@/assets/layout/profile/user-step.svg";
import brandStep from "@/assets/layout/profile/brand-step.svg";
// For Login component
import recentWork01 from "@/assets/layout/login/recent-work-01.jpg";
import recentWork02 from "@/assets/layout/login/recent-work-02.jpg";
import recentWork03 from "@/assets/layout/login/recent-work-03.jpg";
import recentWork04 from "@/assets/layout/login/recent-work-04.jpg";
import stepChoose from "@/assets/layout/login/step-order.jpg";
import stepOrder from "@/assets/layout/login/step-choose.jpg";
import stepShip from "@/assets/layout/login/step-ship.jpg";
import lineOrange01 from "@/assets/layout/login/line-orange-01.png";
import lineRed01 from "@/assets/layout/login/line-red-01.png";
import lineViolet01 from "@/assets/layout/login/line-violet-01.png";
import lineViolet02 from "@/assets/layout/login/line-violet-02.png";
import articlePrTimes from "@/assets/layout/login/article-pr-times.png";
import articleWhenInManila from "@/assets/layout/login/article-when-in-manila.svg";
// For Work With Me page
import brushPink2 from "@/assets/layout/brush-pink-2.svg";
import brushPink3 from "@/assets/layout/brush-pink-3.svg";
import ellipse2 from "@/assets/layout/ellipse-2.svg";
import dots from "@/assets/layout/dots.svg";
import twinkle4 from "@/assets/layout/twinkle-4.svg";
import brushBlue2 from "@/assets/layout/brush-blue-2.svg";
import brushBlue3 from "@/assets/layout/brush-blue-3.svg";
import ellipse3 from "@/assets/layout/ellipse-3.svg";
import dots2 from "@/assets/layout/dots-2.svg";
import twinkle5 from "@/assets/layout/twinkle-5.svg";
// For Common usage
import cancel from "@/assets/layout/common/cancel.svg";
import check from "@/assets/layout/common/check.svg";
import pencil from "@/assets/layout/common/pencil.svg";
import star from "@/assets/layout/common/star.svg";
import truck from "@/assets/layout/common/truck.svg";
import videoCamera from "@/assets/layout/common/videoCamera.svg";
import filePlay from "@/assets/layout/common/filePlay.svg";
import money from "@/assets/layout/common/money.svg";
import cart from "@/assets/layout/common/cart.svg";
import calculator from "@/assets/layout/common/calculator.svg";
// For creator content submit page
import brushBlue4 from "@/assets/layout/brush-blue-4.svg";

/** Object mapping of imported images
 * TODO: Add images here
 */
export const LAYOUT_ICONS = {
  checkmark,
  userModal,
  infoModal,
  upload,
  uploadFile,
  tickets,

  // --- For complex backgrounds ---
  dotsHalfHeightYellow,
  dotsHalfHeightWhite,
  dotsYellow,
  dotsWhite,
  lines,
  brushBlue,
  brushPink,
  brushYellow,
  tiktok,
  legal,
  followers,
  twinkle2,
  twinkle3,
  twinkle3_1,
  stars5Yellow,
  ideas,
  ideas2,
  subscribe,
  playButton,
  heart,
  userBadge,
  tiktokBadge,
  squiggle,
  briefcase,
  cloche,
  ellipse,
  handshake,
  hanger,
  horizontalLines,
  laptop,
  lips,
  megaphone,
  plane,
  roundedRectangle,
  target,
  web,

  // --- PlanCard ---
  planBgPink,
  planBgBlue,
  planBgYellow,

  // --- ProfileCard ---
  personWhite,
  userStep,
  brandStep,

  // --- Login Page ---
  recentWork01,
  recentWork02,
  recentWork03,
  recentWork04,
  stepChoose,
  stepOrder,
  stepShip,
  lineOrange01,
  lineRed01,
  lineViolet01,
  lineViolet02,
  articlePrTimes,
  articleWhenInManila,

  // --- Work With Me Page ---
  brushPink2,
  brushPink3,
  ellipse2,
  dots,
  twinkle4,
  brushBlue2,
  brushBlue3,
  ellipse3,
  dots2,
  twinkle5,

  // --- Work With Me Page ---
  brushBlue4,

  // --- Common ---
  cancel,
  check,
  pencil,
  star,
  truck,
  videoCamera,
  filePlay,
  money,
  cart,
  calculator,
};

/** Provides code autocompletion for icon names */
export type LayoutIconName = keyof typeof LAYOUT_ICONS;

export const getLayoutIcon = (iconName: LayoutIconName) => {
  return LAYOUT_ICONS[iconName];
};

export default defineComponent({
  props: {
    name: {
      type: String as PropType<LayoutIconName>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: { getLayoutIcon },
});
